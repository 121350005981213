import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import login_img from "../../assets/images/png/login_img.png";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slice/authSlice"
import { Spinner } from "react-bootstrap/esm";
import { ToastContainer } from "react-toastify";
// import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
function AdminLoginPage() {
  // const [cookies, setCookie] = useCookies(['auth']);
  const { loading, user,success } = useSelector((state) => state.auth)
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const dispatch = useDispatch()
  // Update form data when input fields change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(formData))
  };
  useEffect(() => {
    if (success) {
      Cookies.set('auth',JSON.stringify( user), { expires: 1 });
      window.location.reload();
    }
  }, [success])

  return (
    <div className="min-vh-100 container-fluid">
      <ToastContainer />
      <div className="row flex-column-reverse flex-lg-row justify-content-between align-items-center">
        <div
          className="col-lg-6 col-xl-5 d-flex justify-content-center position-relative mt-5 mt-lg-0"
          style={{ height: "100%" }}
        >
          <div className="col-9">
            <>
              <form onSubmit={handleSubmit}>
                <div className="rounded-3 px-md-5 position-relative">
                  <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-black">
                    Login
                  </h3>
                  <input
                    onChange={handleChange}
                    value={formData.email}
                    className="px-3 mt-4 custom_input border-1 py-2 w-100 rounded-2"
                    type="email"
                    required
                    placeholder="Enter Email"
                    name="email"
                  />
                  <input
                    onChange={handleChange}
                    value={formData.password}
                    className="px-3 mt-4 custom_input border-1 py-2 w-100 rounded-2 mt-3"
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    required
                  />
                  <div className="d-flex mt-1 justify-content-end">
                    <Link to="/forget-password">
                      <p className="cursor-pointer text-end text-black">
                        Forgot your password?
                      </p>
                    </Link>
                  </div>
                  <div className="mt-4">
                    <button
                      disabled={loading}
                      type="submit"
                      className="py-2 px-5 mt-2 bg-primary text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
                    >
                      {loading ? <Spinner /> : " Submit"}

                    </button>
                  </div>
                </div>
              </form>
            </>
          </div>
        </div>
        <div
          className="col-lg-6 mt-5 mt-lg-0 px-0 custom-img-login"

        >
          <div className="custom_bg w-100 h-100">
            <img className="w-100 h-100" src={login_img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLoginPage;
