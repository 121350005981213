import React, { useState, useRef, useEffect } from "react";
import icon from "../../assets/images/png/icon.jpg";
import { useNavigate } from "react-router-dom/dist";
import ImoprtModal from "../tejar-supplier-admin-panel/ImoprtModal";
import FilterModal from "../tejar-supplier-admin-panel/FilterModal";
import AddProduct from "../tejar-supplier-admin-panel/AddProduct";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentPage, getProducts } from "../../redux/slice/productSlice";
import LoadingSpinner from "./LoadingSpinner";
import ImageUrlGenerator from "./ImageUrlGenerater";

const CourseTab = () => {
  const [imports, setImport] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { products, loading, success, filterData, productType, page } =
    useSelector((state) => state.product);
  const [ImageGenerator, setImageGenerator] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items per page

  const toggleAddProductModal = () => {
    setAddProduct(!addProduct);
  };

  const handleImportChange = (e) => {
    setImport(false);
  };

  const toggleFilterModal = () => {
    setFilter(!filter);
  };
  const toggleImageGenerator = () => {
    setImageGenerator(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(changeCurrentPage(pageNumber));
  };

  const applyFilters = () => {
    // Apply filters to the products array

    return products.filter((product) => {
      // Check all filter criteria
      return (
        (!filterData.name ||
          product.name.toLowerCase().includes(filterData.name.toLowerCase())) &&
        (!filterData.localizedName ||
          product.localized_name
            .toLowerCase()
            .includes(filterData.localizedName.toLowerCase())) &&
        (!filterData.category ||
          product.category_id == parseInt(filterData.category)) &&
        (!filterData.type || product.type === parseInt(filterData.type))
      );
    });
  };
  useEffect(() => {
    if (page == 1) {
      setCurrentPage(1);
    }
  }, [page]);
  useEffect(() => {
    if (success) {
      dispatch(getProducts({ productType: productType, page: 1 }));
      setImport(false);
    }
  }, [success]);

  useEffect(() => {
    dispatch(getProducts({ productType: productType, page: 1 }));
  }, []);

  const renderProducts = () => {
    const filteredProducts = applyFilters();
    // Calculate start and end indexes for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the products array based on the current page
    return filteredProducts.slice(startIndex, endIndex).map((item, index) => (
      <tr
        key={index}
        onClick={() => navigate(`/products/${item.uuid}`)}
        className=" cursor-pointer"
      >
        <td className="py-2 text-secondary text-center ff_heebo px-3 border-bottom">
          <img
            className="rounded-5"
            width={50}
            height={50}
            src={item?.image}
            alt="image"
          />
        </td>
        <td className="py-2 text-secondary text-center ff_heebo px-3 border-bottom">
          {item?.name}
        </td>
        <td className="py-2 text-secondary text-center ff_heebo px-3 border-bottom">
          {item?.category_name}
        </td>
        <td className="py-2 text-secondary text-center ff_heebo px-3 border-bottom">
          <p
            className={
              item?.available_status === 1
                ? "approved_tab rounded-2 py-1 px-2 mb-0 d-inline-block"
                : "review_tab d-inline-block rounded-2 mb-0 py-1 px-2"
            }
          >
            {item?.available_status === 1 ? "Approved" : "Under Review"}
          </p>
        </td>
      </tr>
    ));
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(applyFilters().length / itemsPerPage);
    const maxPagesToShow = 5;
    const currentPageIndex = currentPage - 1;
    const pagination = [];
    if (totalPages <= 1) {
      return null;
    }

    if (totalPages <= maxPagesToShow) {
      // If total pages are less than or equal to the maximum pages to show,
      // display all pages without ellipsis
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(
          <li
            key={i}
            className={`page-item ${currentPage === i ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }
    } else {
      // Calculate start and end page indexes
      let startPage = Math.max(
        currentPageIndex - Math.floor(maxPagesToShow / 2),
        0
      );
      let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages - 1);

      // Adjust start and end page indexes if near the beginning or end
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(endPage - maxPagesToShow + 1, 0);
      }

      // Render page buttons
      for (let i = startPage; i <= endPage; i++) {
        pagination.push(
          <li
            key={i + 1}
            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </button>
          </li>
        );
      }

      // Render ellipsis if not at the beginning
      if (startPage > 0) {
        pagination.unshift(
          <li key="ellipsis-start" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      // Render ellipsis if not at the end
      if (endPage < totalPages - 1) {
        pagination.push(
          <li key="ellipsis-end" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    return pagination;
  };

  return (
    <div className="position-relative h-100 d-flex flex-column w-100 vh-100 overflow-hidden ">
      {loading ? <LoadingSpinner /> : ""}
      <>
        <div>
          <div className="d-flex justify-content-between align-items-center py-sm-3 my_3 ms-3 py-2">
            <h2 className=" fs_37 fw-semibold ff_heebo py-1 mb-0">Products</h2>
          </div>
          <div className="border mb-4 "></div>
          <div className="d-flex align-items-center justify-content-md-between justify-content-start flex-wrap mb-4 me-3 mt-2">
            <div></div>
            <div className="gap-2 d-flex flex-wrap">
              <button
                onClick={() => setImageGenerator(true)}
                className="px-4 ms-2 py-2 rounded-1 bg-secondary text-white border-0"
              >
                Image Url Generator
              </button>
              <button
                onClick={() => setImport(true)}
                className="px-4 ms-2 py-2 rounded-1 bg-secondary text-white border-0"
              >
                Import
              </button>

              <button
                className="px-4 ms-2 py-2 rounded-1 bg-secondary text-white border-0"
                onClick={toggleFilterModal}
              >
                Filter
              </button>
              <button
                onClick={toggleAddProductModal}
                className="px-4 ms-2 py-2 rounded-1 bg-primary text-white border-0"
              >
                Add Product
              </button>
            </div>
          </div>
        </div>

        <div className="h-100 px-sm-3 px-2 table_scroll">
          <div className="card  rounded-0 ">
            <table className="w-100">
              <thead>
                <tr className="">
                  <th className="py-3 text-secondary text-center ff_heebo px-3 border-bottom"></th>
                  <th className="py-3 text-secondary text-center ff_heebo px-3 border-bottom">
                    Name
                  </th>
                  <th className="py-3 text-secondary text-center ff_heebo px-3 border-bottom">
                    Category
                  </th>
                  <th className="py-3 text-secondary text-center ff_heebo px-3 border-bottom">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>{renderProducts()}</tbody>
            </table>
            {applyFilters().length === 0 ? (
              <div className="d-flex justify-content-center h-100 align-items-center">
                <p className="py-3 text-center fs-4 fw-bold">
                  No Product Found
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-min-2000-flex justify-content-end d-max-2000-none text-end me-3 mt-3">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {loading ? null : renderPagination()}
              </ul>
            </nav>
          </div>
        </div>
        {/* Pagination Controls */}
        <div className="d-max-2000-flex justify-content-end d-xxxl-none text-end me-3 mt-3">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              {loading ? null : renderPagination()}
            </ul>
          </nav>
        </div>
      </>

      <AddProduct
        addProduct={addProduct}
        toggleAddProductModal={toggleAddProductModal}
      />
      <ImageUrlGenerator
        ImageGenerator={ImageGenerator}
        toggleImageGenerator={toggleImageGenerator}
      />
      <FilterModal filter={filter} toggleFilterModal={toggleFilterModal} />
      <ImoprtModal imports={imports} handleImportChange={handleImportChange} />
    </div>
  );
};

export default CourseTab;
